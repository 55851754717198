import React from "react";
import {
    GatsbyBackgroundImage,
    Root,
    ContentWrapper,
    Content,
    Header,
    Description,
    Info,
    Meta,
    Logo,
    RelatedTechnologies,
    StyledLink
} from "./project-hero-styles";
import { CenterWrapper } from '../../primitives/index';
import HtmlContentWrapper from "../../_molecules/html-content-wrapper";
import { TechnologyViewModel } from '../../../framework/interfaces/technology-view-model.interface';

const renderRelatedTechnologiesIcons = (techWithLogo: TechnologyViewModel[]) => {
    return (
        <RelatedTechnologies>
        { 
            techWithLogo.map(t => (
                <Logo
                    src={t.logo}
                    alt={t.name}
                    name={t.name}
                />
            ))
        }
        </RelatedTechnologies>
    );
}    

export interface PostHeroProps {
    images: any[];
    title: string;
    description: string;
    meta: string[];
    tech: TechnologyViewModel[]
    previewLink?: string;
    previewLabel: string;
}

const ProjectHero = (props: PostHeroProps) => (
    <GatsbyBackgroundImage
        Tag="section"
        fluid={props.images[0].src.childImageSharp.fluid}
    >            
        <Root>
            <ContentWrapper>
                <CenterWrapper>
                    <Content>
                        <Header>{props.title}</Header>
                        <Info>
                            { props.meta.map(m => <Meta>{ m }</Meta>) }
                            { props.previewLink && <Meta><StyledLink to={props.previewLink}>{ props.previewLabel }</StyledLink></Meta>}
                        </Info>
                        <HtmlContentWrapper>
                            <Description>
                                {props.description}
                            </Description>
                        </HtmlContentWrapper>
                        {
                            props.tech
                            && props.tech.length > 0
                            && renderRelatedTechnologiesIcons(props.tech)
                        }
                    </Content>
                </CenterWrapper>
            </ContentWrapper>
        </Root>
    </GatsbyBackgroundImage>
);

export default ProjectHero;
