import styled from "styled-components";
import { textSecondary, borderRadius, backgroundPrimary, bpMobilePhablet, textPrimary } from "../../styles/_theme-variables";
import { articleFont, transitionTime } from "../../styles/_mixins";
import { Link } from "gatsby";
import { fluidFontSizes } from '../../styles/_calculations';

export const Root = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const Controls = styled.div`
    ${articleFont};
    ${fluidFontSizes.bodySize}
    padding: 1em;
    display: grid;

    @media screen and (min-width: ${bpMobilePhablet}) {
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;

        grid-template-areas:
            "Inputs Buttons"
            "Slider Slider";
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;

        grid-template-areas:
            "Buttons"
            "Inputs"
            "Slider";
    }
`;

export const Inputs = styled.div`
    grid-area: Inputs;
    display: flex;
    color: ${textPrimary};

    @media screen and (max-width: ${bpMobilePhablet}) {
        justify-content: center;
    }
`;

export const Buttons = styled.div`
    grid-area: Buttons;
    text-align: right;

    @media screen and (max-width: ${bpMobilePhablet}) {
        text-align: center;
    }
`;

export const Slider = styled.div`
    grid-area: Slider;
`;

export const SlideControl = styled.input`
    width: 100%;
`;

export const FrameContainer = styled.div`
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
`;

export const Wrapper = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: width ${transitionTime} ease-in;
`;

export const Frame = styled.iframe`
    width: 100%;
    min-height: 100vh;
    flex: 1;
    margin-bottom: 1em;
    transition: width ${transitionTime} ease-in;
`;

export const Handle = styled.div`
    height: 100%;
    width: 1.4rem;
    background-color: black;
`;

export const Textbox = styled.input`
    ${articleFont};
    border: none;
    background-color: transparent;
    color: ${textPrimary};
    width: 4ch;
    text-align: right;
    ${fluidFontSizes.bodySize}
`;

export const TextboxWrapper = styled.div`
    ${fluidFontSizes.bodySize}
    margin-right: 0.5em;
`;

export const Button = styled.button`
    ${articleFont};
    ${fluidFontSizes.bodySize}
    padding: 0.5em;
    margin: 0.3em;
    text-align: center;
    color: ${textSecondary};
    border: 1px solid ${textSecondary};
    border-radius: ${borderRadius};
    text-decoration: none;
    background-color: ${backgroundPrimary};
    transition: background-color ${transitionTime} ease-in, color ${transitionTime} ease-in;

    &:hover {
        background-color: ${textSecondary};
        color: ${backgroundPrimary};
    }
`;

export const StyledLink = styled(Link)`
    color: ${textSecondary};
    ${articleFont};
`;