import styled from "styled-components";

const HtmlContentWrapper = styled.div`
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: 64em) {
        max-width: 60rem;
    }
`;

export default HtmlContentWrapper;
