import React, { useEffect, useState, useRef } from "react";
import { graphql } from "gatsby";
import SEO from "../../components/seo";
import { Frame, Wrapper, FrameContainer, Root, Controls, Inputs, Buttons, Slider, Textbox, TextboxWrapper, Button, SlideControl, StyledLink } from './styled';
import { ThemeContext } from "styled-components";
import ProjectHero from '../../components/_organisms/project-hero/index';

const useWindowSize = (handler) => {
    const isClient = typeof window === 'object';

    const getSize = () => ({
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
    });

    const [isFullMode, setFullMode] = useState(false);
    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (isClient) {    
            const handleResize = () => {
            setWindowSize(getSize());
            }
        
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            handler(isFullMode);
        }

        if (isClient) {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    });

    return {
        windowSize,
        isFullMode,
        setFullMode
    }
}

const useInterval = (callback, delay) => {
    const savedCallback = useRef<any>();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      const tick = () => {
        if (savedCallback && savedCallback.current) {
            savedCallback.current();
        }
      };

      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

const Template = (props) => {
    const { data } = props;
    const padding = 10 * 2;    
    const minViewportWidth = 240;
    let sw = minViewportWidth;
    const bodySize = 16;


    const [currentFrameWidth, setCurrentFramewidth] = useState(0);
    const [currentWrapperWidth, setCurrentWrapperWidth] = useState(0);
    const [hayMode, setHayMode] = useState(false);
    const [discoMode, setDiscoMode] = useState(false);
    const [sizePx, setSizePx] = useState(0);
    const [sizeEm, setSizeEm] = useState(0);
    const [maxViewportWidth, setMaxViewportWidth] = useState(minViewportWidth);

    useEffect(() => {
        sizeFull();
    }, [maxViewportWidth])

    useEffect(() => {
        const clientWidth = (typeof document === 'object' && document.body.clientWidth) || 0; 

        sw = clientWidth - padding;
        setMaxViewportWidth(clientWidth - padding);

        updateSizeReading(currentFrameWidth, null);
    }, [])

    const handleResize = (fullMode) => {
        const clientWidth = (typeof document === 'object' && document.body.clientWidth) || 0; 
        sw = clientWidth;    
        setMaxViewportWidth(clientWidth - padding);
        if (fullMode) {
            sizeFull();
        }
    }

    const { setFullMode, isFullMode } = useWindowSize(handleResize);

    const { projectsJson } = data;

    const project = projectsJson;

    const seoImage = project.openGraphImage[0].src.childImageSharp.fixed.src;

    const projectSource = project.previewUrl;

    const getRandom = (min, max) => {
        const num = Math.random() * (max - min) + min;
        return parseInt(num);
    }

    const sizeLarge = () => {
        sizeIFrame(1200, false);
    }

    const sizeMedium = () => {
        const newSize = getRandom(500, 800);
        sizeIFrame(newSize, false);
    }

    const sizeSmall = () => {
        sizeIFrame(minViewportWidth, false);
    }

    const sizeFull = () => {
        sizeIFrame(maxViewportWidth, false);
    }

    const sizeRandom = () => {
        const newSize = getRandom(minViewportWidth, sw);
        sizeIFrame(newSize, false);
    }

    const fullModeOff = () => {
        setFullMode(false);
    }

    const sizeIFrame = (size, animate) => {
        let theSize;

        if (size > maxViewportWidth) {
            theSize = maxViewportWidth;
        } else if (size < minViewportWidth) {
            theSize = minViewportWidth;
        } else {
            theSize = size;
        }

        setCurrentFramewidth(theSize);
        setCurrentWrapperWidth(theSize);

        updateSizeReading(theSize, null);
    };

    const setLarge = () => {
        killDisco();
        killHay();
        fullModeOff();
        sizeLarge();
    };

    const setMedium = () => {
        killDisco();
        killHay();
        fullModeOff();
        sizeMedium();
    }

    const setSmall = () => {
        killDisco();
        killHay();
        fullModeOff();
        sizeSmall();
    }

    const setFull = () => {
        killDisco();
        killHay();
        setFullMode(true);
        sizeFull();
    }

    const setRandom = () => {
        killDisco();
        killHay();
        setFullMode(false);
        sizeRandom();
    }

    const startHay = () => {
        sizeIFrame(minViewportWidth, false);
        setHayMode(true);
    }

    const killHay = () => {
        setHayMode(false);
    }

    const handleHay = () => {
        killDisco();
        if (hayMode) {
            killHay();
        } else {
            startHay();
        }
    }

    useInterval(() => {
        if (discoMode) {
            const newWidth = getRandom(minViewportWidth, sw);
            sizeIFrame(newWidth, false);
        }
      }, 1000);

    useInterval(() => {
        if (hayMode) {
            const newWidth = currentFrameWidth + 10;
            sizeIFrame(newWidth, false);
        }
    }, 200);

    const startDisco = () => {
        setDiscoMode(true);
    }

    const killDisco = () => {
        setDiscoMode(false);
    }    

    const handleDisco = () => {
        killHay();
        setFullMode(false);

        if (discoMode) {
            killDisco();
        } else {
            startDisco();
        }
    }

    const handlePixelWidthChange = e => {
        const value = parseFloat(e.target.value);
        setSizePx(e.target.value);
        sizeIFrame(value, false);
        updateSizeReading(e.target.value, null);
    };

    const handleEmWidthChange = e => {
        const value = parseFloat(e.target.value);
        sizeIFrame(value * bodySize, false);
        updateSizeReading(e.target.value, 'em');
    };

    const updateSizeReading = (size, unit) => {
        if (unit === 'em') {
            setSizeEm(size);
            setSizePx(size * bodySize);
        } else {
            setSizePx(size);
            setSizeEm(Math.floor(size / bodySize));
        }        
    };

    const heroImages = project.heroImages;

    const logo = project.logo;

    return (
        <>
            <SEO
                title={`${project.title} - Preview`}
                description={project.metaDescription}
                image={seoImage}
                slug={`/portfolio/preview/${project.slug}`}
            />
            <Root>
                <ProjectHero
                    images={heroImages}
                    title={`${project.title} (preview)`}
                    description={project.metaDescription}
                    meta={[]}
                    previewLink={project.previewUrl && `portfolio/${project.slug}`}
                    previewLabel="Learn more..."
                >
                </ProjectHero>
                <Controls>        
                    <Inputs>
                        <TextboxWrapper><Textbox value={sizePx} onChange={handlePixelWidthChange} aria-label="width-in-pixels" />px</TextboxWrapper>
                        <TextboxWrapper><Textbox value={sizeEm} onChange={handleEmWidthChange} aria-label="width-in-ems" />em</TextboxWrapper>
                    </Inputs>                
                    <Buttons>                           
                        <Button onClick={() => setSmall()}>Small</Button>
                        <Button onClick={() => setMedium()}>Medium</Button>
                        <Button onClick={() => setLarge()}>Large</Button>
                        <Button onClick={() => setFull()}>Full</Button>
                        <Button onClick={() => setRandom()}>Random</Button>
                        <Button onClick={() => handleHay()}>Hay Sizing</Button>
                        <Button onClick={() => handleDisco()}>Disco Sizes</Button>
                    </Buttons>
                    <Slider>
                        <SlideControl type="range" name="points" min={minViewportWidth} max={maxViewportWidth} value={sizePx} onChange={handlePixelWidthChange} aria-label="width-slider" />
                    </Slider>
                </Controls> 
                <FrameContainer>
                    <Wrapper style={{ width: `${currentWrapperWidth}px` }}>
                        <Frame
                            style={{ width: `${currentFrameWidth}px` }}
                            id="sg-viewport"
                            src={projectSource}
                            sandbox="allow-same-origin allow-scripts allow-forms"
                            title="preview-frame"
                        ></Frame>
                    </Wrapper>
                </FrameContainer>
            </Root>
        </>
    );
};

export default ({
    data
}) => (
    <ThemeContext.Consumer>
        {
            theme => (<Template data={data} theme={theme} />)
        }
    </ThemeContext.Consumer>
)

export const previewQuery = graphql`
    query ProjectsByPath($slug: String!) {
        projectsJson(slug: { eq: $slug }) {
            id
            title
            metaDescription
            logo {
                name
                src {
                    childImageSharp {
                        fluid(maxHeight: 120) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                        portrait: fluid(maxWidth: 120, maxHeight: 120) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }                    
                    extension
                    publicURL
                }
            }
            heroImages {
                name
                src {
                    childImageSharp {
                        fixed(width: 700, height: 350, cropFocus: NORTH) {
                            ...GatsbyImageSharpFixed
                        }  
                        fluid(quality: 90, maxWidth: 1920, maxHeight: 350) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                        fluidThumb: fluid(quality: 90, maxWidth: 720, maxHeight: 480, cropFocus: NORTH) {
                            ...GatsbyImageSharpFluid_withWebp
                        }                           
                    }   
                }
            }
            openGraphImage: heroImages {
                name
                src {
                    childImageSharp {
                        fixed(width: 1200, height: 630) {
                            src
                        }
                    }
                }
            }
            bodyContent: _bodyContent {
                id
                html
            }
            date
            relatedTechnologies
            slug
            tags
            series
            previewUrl
        }
    }
`;